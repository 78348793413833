// extracted by mini-css-extract-plugin
export const bg = "resource-wrapper-module--bg--3BM7U";
export const about = "resource-wrapper-module--about--1y1Op";
export const toggleHeader = "resource-wrapper-module--toggleHeader--1oQCM";
export const saveText = "resource-wrapper-module--saveText--1e88x";
export const saveIcon = "resource-wrapper-module--saveIcon--2tOYj";
export const saveHeader = "resource-wrapper-module--saveHeader--3ZRLn";
export const saveHeaderMobile = "resource-wrapper-module--saveHeaderMobile--3VP_e";
export const list = "resource-wrapper-module--list--3shCy";
export const toggle = "resource-wrapper-module--toggle--13pan";
export const subtext = "resource-wrapper-module--subtext--iZx21";
export const benefit = "resource-wrapper-module--benefit--1aOii";
export const check = "resource-wrapper-module--check--3XrUM";
export const quote = "resource-wrapper-module--quote--W4dib";
export const icon = "resource-wrapper-module--icon--138wA";
export const copy = "resource-wrapper-module--copy--AekYU";
export const contentParent = "resource-wrapper-module--contentParent--srYOQ";
export const disclaimer = "resource-wrapper-module--disclaimer--5336V";
export const content = "resource-wrapper-module--content--2g088";
export const contentImageParent = "resource-wrapper-module--contentImageParent--P_asB";
export const contentImage = "resource-wrapper-module--contentImage--1Kdbg";
export const iframeParent = "resource-wrapper-module--iframeParent--28NxJ";
export const iframe = "resource-wrapper-module--iframe--3qMWA";
export const iframeParentTao = "resource-wrapper-module--iframeParentTao--1RKKe";
export const video = "resource-wrapper-module--video--2fQvb";
export const poll = "resource-wrapper-module--poll--B3XZX";
export const pollComplete = "resource-wrapper-module--pollComplete--2eXWt";
export const feedbackQualify = "resource-wrapper-module--feedbackQualify--HAfZT";
export const qualifyHeader = "resource-wrapper-module--qualifyHeader--2KoYv";
export const qualifyOptions = "resource-wrapper-module--qualifyOptions--3IN5V";
export const qualifyCheckbox = "resource-wrapper-module--qualifyCheckbox--2i8NG";
export const submitWrapper = "resource-wrapper-module--submitWrapper--1kr0U";
export const resources = "resource-wrapper-module--resources--XA7XW";
export const row = "resource-wrapper-module--row--3DsQN";
export const ctaCard = "resource-wrapper-module--ctaCard--V-srI";
export const ctaCardBackgroundLg = "resource-wrapper-module--ctaCardBackgroundLg--1MUCM";
export const ctaCardBackgroundSm = "resource-wrapper-module--ctaCardBackgroundSm--2Rs83";
export const ctaCardHeader = "resource-wrapper-module--ctaCardHeader--3U5nY";
export const ctaCardContent = "resource-wrapper-module--ctaCardContent--NHr38";
export const ctaCardChecklistItem = "resource-wrapper-module--ctaCardChecklistItem--1APA4";
export const whiteCheck = "resource-wrapper-module--whiteCheck---Yfek";
export const checklistText = "resource-wrapper-module--checklistText--3MSYM";
export const ctaCardButton = "resource-wrapper-module--ctaCardButton--3uDQi";