// extracted by mini-css-extract-plugin
export const grid = "resource-module--grid--1LZ3I";
export const card = "resource-module--card--3UoJj";
export const imageClassName = "resource-module--imageClassName--SvMUB";
export const image = "resource-module--image--1RteB";
export const body = "resource-module--body--3zEbR";
export const resourceTitle = "resource-module--resourceTitle--1ndwy";
export const provider = "resource-module--provider--23h2f";
export const providerLogo = "resource-module--providerLogo--XC0Aw";
export const favoriteWrapper = "resource-module--favoriteWrapper--3D479";
export const favorite = "resource-module--favorite--1Mn6P";
export const favoriteHover = "resource-module--favoriteHover--1wRO9";
export const filled = "resource-module--filled--3bfNv";
export const tag = "resource-module--tag--2fbGH";
export const disabled = "resource-module--disabled--2xcaV";
export const compact = "resource-module--compact--3UQjO";