import { useContext, useEffect } from 'react';
import SaveBookmarkContext from '@components/bookmarks/SaveBookmarkContext';
import { useMPEvent } from '@util/mixpanel';

function useBookmarkHandler({ isSaved, toggleSaved, analyticsData }) {
  const { seen, toggleShow } = useContext(SaveBookmarkContext);
  const {
    trackAddBookmarkResource,
    trackRemoveBookmarkResource,
  } = useMPEvent();

  function toggleBookmark() {
    if (!seen) toggleShow(true);
    // fire mixpanel events
    if (isSaved) trackRemoveBookmarkResource(analyticsData);
    else trackAddBookmarkResource(analyticsData);
    // toggle the bookmark
    toggleSaved();
  }

  return [toggleBookmark];
}

// requires a start and stop funtion from parent component state
export default function BookmarkHandler({ run, toggle, ...rest }) {
  const [toggleBookmark] = useBookmarkHandler(rest);
  useEffect(() => {
    if (run) {
      toggleBookmark();
      toggle(false);
    }
  }, [run]);
  return null;
}
