import React, { useState, useEffect, useRef, Ref } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import * as styles from '@css/modules/landing-resource.module.scss';
import * as resourceStyles from '@css/modules/resource.module.scss';

import Resource from './Resource';
import { ResourceData, ResourceSelectorData } from '../interfaces/ResourceData';

// eslint-disable-next-line import/prefer-default-export
export const ResourceGrid = ({
  resources,
  pageName,
  locale,
  compact = false,
  showMore,
  showMoreAmount = 6,
  setShowMore,
  selector,
}: ResourceGridProps) => {
  const [isClient, setIsClient] = useState(false);
  const rowEl = useRef(null);
  const firstNewResultRef: Ref<HTMLAnchorElement> = useRef(null);
  const firstNewResultIndex = showMore - showMoreAmount;

  useEffect(() => setIsClient(true), []);

  useEffect(() => {
    if (rowEl?.current) {
      rowEl.current.scrollLeft = 0;
    }
  }, [resources.length]);

  useEffect(() => {
    firstNewResultRef?.current?.focus()
  }, [firstNewResultIndex])

  if (!isClient) return null;

  if (resources.length) {
    return (
      <Row className="mb-5">
        <Col xs={12} className="mt-4">
          <Row ref={rowEl} noGutters={compact} className={resourceStyles.grid}>
            {resources.length ? (
              resources
                .slice(0, showMore)
                .map((resource, index) => {
                  const isFirstNewResult = index === firstNewResultIndex
                  return (
                  <Resource
                    key={`resource-list-card-${resource.slug}`}
                    data={resource}
                    node_locale={locale}
                    from={pageName}
                    compact={compact}
                    ref={isFirstNewResult ? firstNewResultRef : undefined}
                  />
                )})
            ) : (
              <Col xs={12} className={styles.empty}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: selector.empty.childMarkdownRemark.html,
                  }}
                />
              </Col>
            )}
            {showMore < resources.length ? (
              <Col xs={6} lg={12} className="text-center mt-2">
                <Button
                  className="text-secondary text-bolder text-18"
                  variant="clear"
                  onClick={() => {
                    setShowMore(showMore + showMoreAmount);
                  }}
                >
                  {selector.showMore}
                </Button>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    );
  }

  // Display a message saying that there are no other resources that match this
  // resource's `wellnessCategory`.
  return (
    <Col xs={12} className={styles.empty}>
      <div
        dangerouslySetInnerHTML={{
          __html: selector.empty.childMarkdownRemark.html,
        }}
      />
    </Col>
  );
};

interface ResourceGridProps {
  resources: ResourceData[];
  pageName: string;
  locale: 'en-CA' | 'fr-CA';
  selector: ResourceSelectorData;
  compact?: boolean;
  showMore: number;
  showMoreAmount?: number;
  setShowMore: (value: number) => void;
}
