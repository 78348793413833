import { useEffect, useState } from 'react';
import store from 'store';
import sessionStorage from '@util/sessionStorage';

const storages = {
  local: store,
  session: sessionStorage,
};
// set empty array defaults to storages
if (typeof window !== 'undefined') {
  const localStorageBookmarks = storages.local.get('bookmarks');
  if (!localStorageBookmarks) store.set('bookmarks', []);
  const sessionStorageBookmarks = storages.session.get('bookmarks');
  if (!sessionStorageBookmarks) storages.session.set('bookmarks', []);
  store.watch('bookmarkStorage', () => {
    const activeStorage = store.get('bookmarkStorage');
    // activeStorage becomes the source of truth, copy over what was previously saved
    // on toggle back, the same list is copied over
    // if browser session has closed and session was active, the list will be empty, but this toggle will not display anyway
    const previousStorage = activeStorage === 'local' ? 'session' : 'local';
    const previousList = storages[previousStorage].get('bookmarks') || [];
    storages[activeStorage].set('bookmarks', previousList);
    storages[previousStorage].set('bookmarks', []);
  });
}

export default function useIsSaved(id) {
  const [isSaved, setSavedStatus] = useState();
  const [run, check] = useState(false);
  const [storage, toggleStorage] = useState(
    store.get('bookmarkStorage') || 'session',
  );

  // when bookmarks list or type of storage changes, ensure the resource components check themselves
  useEffect(() => {
    store.watch('bookmarks', () => check(true));
    sessionStorage.watch('bookmarks', () => check(true));
    store.watch('bookmarkStorage', () =>
      toggleStorage(store.get('bookmarkStorage')),
    );
  }, []);

  useEffect(() => {
    if (id) {
      const bookmarks = storages[storage].get('bookmarks') || [];
      setSavedStatus(bookmarks?.includes(id));
      check(false);
    }
  }, [id, run, storage]);

  function toggleSaved() {
    let bookmarks = storages[storage].get('bookmarks') || [];
    bookmarks = bookmarks.includes(id)
      ? bookmarks.filter((b) => b !== id)
      : [...bookmarks, id];
    storages[storage].set('bookmarks', bookmarks);
  }

  return [isSaved, toggleSaved, check];
}
