import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import store from 'store';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
  ChatSquareQuote,
  ChevronDown,
  Star,
  StarFill,
} from 'react-bootstrap-icons';

import PageLayout from '@components/layout/PageLayout';
import BookmarkHandler from '@components/bookmarks/util';
import { ResourceHero } from '@components/layout/PageHero';
import ResourceTile from '@components/Resource';
import { ResourceGrid } from '@components/ResourceGrid';
import * as styles from '@css/modules/resource-wrapper.module.scss';
import primaryCheck from '@css/img/check-primary.png';
import ctaBackgroundLarge from '@css/img/resource-cta-lg.png';
import ctaBackgroundSmall from '@css/img/resource-cta-sm.png';
import locale from '@util/locale';
import { useMPEvent } from '@util/mixpanel';
import useIsSaved from '@util/useIsSaved';

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { target } = node.data;
      if (target.file?.contentType.includes('audio')) {
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio controls>
            <source src={target.file.url} />
          </audio>
        );
      }
      if (target.file?.contentType.includes('video')) {
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video className={styles.video} autoplay controls>
            <source src={target.file.url} type={target.file.contentType} />
          </video>
        );
      }
      return (
        <div className={styles.contentImageParent}>
          <Img
            fluid={target.fluid}
            sizes={target.sizes}
            className={styles.contentImage}
          />
        </div>
      );
    },
  },
};

function Toggle({ header }) {
  return (
    <Accordion.Toggle
      className={`${styles.toggle}`}
      eventKey="toggle"
      as="button"
    >
      <h4 className="m-0">{header}</h4>
      <ChevronDown />
    </Accordion.Toggle>
  );
}

const AboutContent = ({ header, subHeader, list }) => (
  <>
    {header ? <h3 className="m-0">{header}</h3> : null}
    {subHeader ? <p className={`mt-2 ${styles.subtext}`}>{subHeader}</p> : null}
    {list?.map((item) => (
      <div className={styles.benefit} key={item}>
        <img src={primaryCheck} alt="" className={styles.check} />
        {item}
      </div>
    ))}
  </>
);

export default function Resource({ data, pageContext }) {
  const {
    contentfulResourcePage,
    allContentfulResourcePage,
    contentfulHomePage,
  } = data;
  const {
    node_locale: nodeLocale,
    id,
    uuid,
    slug,
    title,
    tags,
    provider,
    tileImage,
    heroImage,
    whatItDoes,
    whatItDoesList,
    howItHelps,
    howItHelpsList,
    quote,
    quoteAuthor,
    cta,
    resourceDisclaimer,
    content,
    iframe,
  } = contentfulResourcePage;
  const { selector } = contentfulHomePage;
  const resources = allContentfulResourcePage.edges.map((r) => r.node);
  const [feedback, toggleFeedback] = useState(false);
  const [helpful, setHelpful] = useState();
  const [usePersonalization, togglePersonalization] = useState(true);
  const [showMore, setShowMore] = useState(6);
  const [isSaved, toggleSaved] = useIsSaved(id);
  const [bookmarkHandler, triggerBookmarkHandler] = useState(false);
  const {
    trackPageLoad,
    trackResourceFeedback,
    trackPageInteraction,
  } = useMPEvent();

  const analyticsDefaults = {
    id: uuid,
    name: title,
    slug,
    provider: provider.name,
    language: nodeLocale.toLowerCase(),
    url: typeof window !== 'undefined' ? `/${nodeLocale}/${slug}` : '',
    url_name: 'resource-wrapper',
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const myForm = document.getElementById(event.target.id);
    const formData = new FormData(myForm);

    const body = new URLSearchParams(formData).toString();
    await fetch(`/${nodeLocale}/${slug}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
    });
    toggleFeedback(true);
  }

  async function handleFeedback(value) {
    setHelpful(value);
    trackResourceFeedback({
      ...analyticsDefaults,
      question: 'Did you find this resource useful?',
      value,
    });
  }

  useEffect(() => {
    trackPageLoad(analyticsDefaults);
    const personalization = store.get('personalization');
    if (personalization === false) togglePersonalization(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout
      translations={pageContext.translations}
      node_locale={nodeLocale}
      page={pageContext.pageName}
      pageTitle={title}
    >
      <ResourceHero
        data={{ title, provider, tags, tileImage, heroImage, nodeLocale }}
      />
      <div className={styles.bg} />

      <BookmarkHandler
        run={bookmarkHandler}
        toggle={triggerBookmarkHandler}
        isSaved={isSaved}
        toggleSaved={toggleSaved}
        analyticsData={analyticsDefaults}
      />

      <Container className="py-4">
        <Row>
          <Col xs={12} md={{ offset: 1, span: 9 }} lg={{ span: 10, offset: 1 }}>
            <Card className={`bg-lavender ${styles.about}`}>
              <Card.Body className="px-0">
                <Row className="justify-content-between p-3 pb-4">
                  <Col>
                    <h2 className={`${styles.toggleHeader} text-secondary`}>
                      {locale[nodeLocale].about}
                    </h2>
                  </Col>
                  <Col
                    className={`${styles.saveText} text-right d-none d-lg-flex`}
                    onClick={() => triggerBookmarkHandler(true)}
                    role="button"
                  >
                    {isSaved ? (
                      <StarFill className={styles.saveIcon} />
                    ) : (
                      <Star className={styles.saveIcon} />
                    )}
                    <h2 className={`${styles.saveHeader}`}>
                      {locale[nodeLocale][isSaved ? 'saved' : 'save']}
                    </h2>
                  </Col>
                </Row>
                <Row className="justify-content-between">
                  {/* Desktop */}
                  <Col lg={6} className="d-none d-lg-block pb-4 pb-lg-0">
                    <div className={`bg-white ${styles.list}`}>
                      <AboutContent
                        header={locale[nodeLocale].whatItDoes}
                        subHeader={whatItDoes}
                        list={whatItDoesList}
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="d-none d-lg-block pb-4 pb-lg-0">
                    <div className={`bg-white ${styles.list}`}>
                      <AboutContent
                        header={locale[nodeLocale].howItHelps}
                        subHeader={howItHelps}
                        list={howItHelpsList}
                      />
                    </div>
                  </Col>
                  {/* Mobile */}
                  <Col xs={12} className="d-block d-lg-none pb-4 ">
                    <div className={`bg-white ${styles.list}`}>
                      <Accordion>
                        <Toggle header={locale[nodeLocale].whatItDoes} />
                        <Accordion.Collapse eventKey="toggle">
                          <AboutContent
                            subHeader={whatItDoes}
                            list={whatItDoesList}
                          />
                        </Accordion.Collapse>
                      </Accordion>
                    </div>
                  </Col>
                  <Col xs={12} className="d-block d-lg-none">
                    <div className={`bg-white ${styles.list}`}>
                      <Accordion>
                        <Toggle header={locale[nodeLocale].howItHelps} />
                        <Accordion.Collapse eventKey="toggle">
                          <AboutContent
                            subHeader={howItHelps}
                            list={howItHelpsList}
                          />
                        </Accordion.Collapse>
                      </Accordion>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    className={`${styles.saveText} text-center d-block d-lg-none mt-4`}
                    onClick={() => triggerBookmarkHandler(true)}
                    role="button"
                  >
                    <div
                      className={`${styles.saveHeaderMobile}`}
                      aria-live="assertive"
                      role="alert"
                    >
                      {isSaved ? (
                        <StarFill className={`${styles.saveIcon} mr-2 mt-n2`} />
                      ) : (
                        <Star className={styles.saveIcon} />
                      )}
                      {locale[nodeLocale][isSaved ? 'saved' : 'save']}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {cta ? (
        <Container>
          <Row>
            <Col
              xs={12}
              md={{ offset: 1, span: 9 }}
              lg={{ span: 10, offset: 1 }}
            >
              <div className={styles.ctaCard}>
                <img
                  src={ctaBackgroundLarge}
                  alt=""
                  className={`d-xl-block d-none ${styles.ctaCardBackgroundLg}`}
                />
                <img
                  src={ctaBackgroundSmall}
                  alt=""
                  className={`d-xl-none d-block ${styles.ctaCardBackgroundSm}`}
                />

                <h2 className={styles.ctaCardHeader}>{cta.header}</h2>
                <p className={styles.ctaCardContent}>{cta.copy}</p>

                <Row role="list" className="mb-2">
                  {cta.checklist?.map((item) => (
                    <Col
                      xl={4}
                      lg={6}
                      role="listitem"
                      className={styles.ctaCardChecklistItem}
                    >
                      <img
                        src={primaryCheck}
                        alt=""
                        className={styles.whiteCheck}
                      />
                      <p className={styles.checklistText}>{item}</p>
                    </Col>
                  ))}
                </Row>

                <div className="w-100 d-flex justify-content-center position-relative">
                  <a
                    className={`btn btn-outline-light ${styles.ctaCardButton}`}
                    href={`${
                      process.env.GATSBY_GREENSPACE_URL
                    }/self_guided/signup/?lang=${nodeLocale.toLowerCase()}`}
                  >
                    {cta.buttonCopy}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}

      <Container>
        {quote ? (
          <Row className={styles.quote}>
            <Col
              xs={{ span: 2, offset: 1 }}
              lg={{ offset: 2, span: 1 }}
              className={styles.icon}
            >
              <ChatSquareQuote />
            </Col>
            <Col xs={8} lg={7}>
              <div
                dangerouslySetInnerHTML={{
                  __html: quote.childMarkdownRemark.html,
                }}
                className={`${styles.copy} text-secondary`}
              />
              <p>{quoteAuthor}</p>
            </Col>
          </Row>
        ) : null}
      </Container>

      {/* Resource disclaimer, content and iframe, all optional */}
      <Container className={styles.contentParent}>
        <Row>
          {resourceDisclaimer ? (
            <Col xs={12} md={{ span: 10, offset: 1 }}>
              <div
                className={styles.disclaimer}
                dangerouslySetInnerHTML={{
                  __html: resourceDisclaimer.content.childMarkdownRemark.html,
                }}
              />
            </Col>
          ) : null}
          {content || iframe ? (
            <Col
              xs={12}
              // FIXME
              // md={activeQuestion !== false ? 7 : { span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              className={`${styles.content} text-charcoal`}
              // This is a special case for one resource that wants center-aligned text
              // We can make a more universal solution in the future if this comes up
              // again.
              style={{
                textAlign:
                  slug === 'healthcare-workers' ||
                  slug === 'personnel-de-la-santé'
                    ? 'center'
                    : 'left',
              }}
            >
              {/* eslint-disable no-nested-ternary */}
              {content && !iframe ? (
                renderRichText(content, richTextOptions)
              ) : content && iframe ? (
                <>
                  {renderRichText(content, richTextOptions)}
                  <div
                    className={
                      iframe.includes('tao')
                        ? styles.iframeParentTao
                        : styles.iframeParent
                    }
                  >
                    <iframe
                      className={styles.iframe}
                      src={iframe}
                      title={title}
                    />
                  </div>
                </>
              ) : iframe ? (
                <div className={styles.iframeParent}>
                  <iframe
                    className={styles.iframe}
                    src={iframe}
                    title={title}
                  />
                </div>
              ) : null}
            </Col>
          ) : null}
        </Row>
      </Container>

      {/* Feedback */}
      <Container className={usePersonalization ? 'd-block' : 'd-none'}>
        <Row>
          <Col xs={12}>
            {feedback ? (
              <Row className={`bg-info ${styles.poll}`}>
                <Col
                  xs={12}
                  className="text-center text-secondary text-bold justify-content-center align-items-center align-self-center"
                >
                  <h2 className="mb-0">
                    {locale[nodeLocale].feedback_thankyou}
                  </h2>
                </Col>
              </Row>
            ) : (
              <form
                data-netlify="true"
                name="resource-feedback"
                id="feedback-form"
                netlify
                onSubmit={handleSubmit}
              >
                <Row className={`bg-info ${styles.poll}`}>
                  <Col
                    xs={6}
                    className="text-center text-secondary text-bold justify-content-center align-items-center align-self-center"
                  >
                    <h2 className="h6 mb-0">{locale[nodeLocale].helpful}</h2>
                    <input
                      type="hidden"
                      name="form-name"
                      value="resource-feedback"
                    />
                    <input type="hidden" name="helpful" value={helpful} />
                    <input type="hidden" name="resource" value={slug} />
                  </Col>
                  <Col xs={3}>
                    <Button
                      type="button"
                      variant="outline-secondary"
                      onClick={() => handleFeedback('yes')}
                      block
                    >
                      {locale[nodeLocale].yes}
                    </Button>
                  </Col>
                  <Col xs={3}>
                    <Button
                      type="button"
                      variant="outline-secondary"
                      onClick={() => handleFeedback('no')}
                      block
                    >
                      {locale[nodeLocale].no}
                    </Button>
                  </Col>
                  {helpful ? (
                    <Col
                      className={styles.feedbackQualify}
                      xs={12}
                      md={{ span: 10, offset: 1 }}
                    >
                      <div className={styles.qualifyHeader}>
                        <span>
                          {locale[nodeLocale].feedback[helpful].header}
                        </span>
                      </div>
                      <div className={styles.qualifyOptions}>
                        {locale[nodeLocale].feedback[helpful].options.map(
                          (option, i) => (
                            <Form.Check
                              className={styles.qualifyCheckbox}
                              id={`qualify-checkbox-${i}`}
                              name={`qualify-${i}`}
                              value={option}
                              key={i}
                              type="checkbox"
                              label={option}
                            />
                          ),
                        )}
                      </div>
                      <div className={styles.submitWrapper}>
                        <Button type="submit" variant="secondary">
                          {locale[nodeLocale].sendFeedback}
                        </Button>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </form>
            )}
          </Col>
        </Row>
      </Container>

      <Row noGutters>
        <Col className={`${styles.resources} pb-4`} xs={{ span: 12 }}>
          <Row className={styles.row}>
            {helpful === 'no' ? (
              <Col xs={12} md={{ span: 8, offset: 2 }}>
                <Row>
                  <Col xs={12} className="my-4">
                    <h2 className="mt-4 font-weight-bold">
                      {locale[nodeLocale].mightHelp}
                    </h2>
                  </Col>
                  <Col xs={12} className="mt-4">
                    <Row>
                      {resources.splice(-3, 3).map((r, i) => (
                        <ResourceTile
                          from={pageContext.pageName}
                          key={i}
                          data={r}
                          node_locale={nodeLocale}
                        />
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            ) : null}
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <Row>
                <Col xs={12} className="my-4">
                  <h2 className="mt-4 font-weight-bold">
                    {locale[nodeLocale].tryThese}
                  </h2>
                </Col>
                <Col xs={12} className="mt-4">
                  <ResourceGrid
                    resources={resources}
                    pageName={pageContext.pageName}
                    locale={nodeLocale}
                    showMore={showMore}
                    setShowMore={(value) => {
                      setShowMore(value);
                      trackPageInteraction({
                        ...analyticsDefaults,
                        element: 'resource selector',
                        action: 'load more',
                      });
                    }}
                    selector={selector}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageLayout>
  );
}

export const query = graphql`
  query($node_locale: String!, $slug: String!, $category: String) {
    contentfulHomePage(node_locale: { eq: $node_locale }) {
      selector {
        empty {
          childMarkdownRemark {
            html
          }
        }
        showMore
      }
    }

    contentfulResourcePage(
      node_locale: { eq: $node_locale }
      slug: { eq: $slug }
    ) {
      node_locale
      slug
      title
      canonicalUrl
      id: contentful_id
      uuid
      tags
      cta {
        header
        copy
        checklist
        buttonCopy
      }
      provider {
        name
        url
        logo {
          title
          gatsbyImageData(
            height: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
        branding {
          text
          background
          tag
        }
      }
      tileImage {
        title
        fixed(height: 500, width: 500) {
          ...GatsbyContentfulFixed
        }
      }
      heroImage {
        title
        fixed(height: 500, width: 500) {
          ...GatsbyContentfulFixed
        }
      }
      whatItDoes
      whatItDoesList
      howItHelps
      howItHelpsList
      quote {
        childMarkdownRemark {
          html
        }
      }
      quoteAuthor
      resourceDisclaimer {
        content {
          childMarkdownRemark {
            html
          }
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            file {
              url
              contentType
            }
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      iframe
    }

    allContentfulResourcePage(
      filter: {
        node_locale: { eq: $node_locale }
        wellnessCategory: { eq: $category }
        slug: { ne: $slug }
      }
    ) {
      edges {
        node {
          node_locale
          title
          slug
          id: contentful_id
          uuid
          tags
          externalLink
          canonicalUrl
          wellnessCategory
          age
          provider {
            name
            logo {
              title
              fixed(height: 55) {
                ...GatsbyContentfulFixed
              }
              gatsbyImageData(
                height: 55
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            branding {
              background
              text
              tag
            }
          }
          tileImage {
            title
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            gatsbyImageData(
              height: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;
