import React, { useContext, forwardRef, ForwardedRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { BoxArrowUpRight, Star, StarFill } from 'react-bootstrap-icons';

import SaveBookmarkContext from '@components/bookmarks/SaveBookmarkContext';
import * as styles from '@css/modules/resource.module.scss';
import { useMPEvent } from '@util/mixpanel';
import useIsSaved from '@util/useIsSaved';
import { GAEvent } from '@src/util/analytics/GAEvent';
import { ResourceData } from '@src/interfaces/ResourceData';
import { NodeLocale } from '@src/interfaces/NodeLocale';
import locale from '@util/locale';
import { TooltipTrigger } from '@src/components/TooltipTrigger';

interface ResourceProps {
  node_locale: NodeLocale;
  data: ResourceData;
  compact?: boolean;
  from: string;
  usePersonalization?: boolean;
}

/**
 * Used to handle external and internal resource links
 * https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links
 */
const ResourceLink = forwardRef(({
  resource,
  nodeLocale,
  children,
  activeClassName,
  partiallyActive,
  ...other
}: GatsbyLink, ref: ForwardedRef<HTMLAnchorElement>) => {
  const external = resource.externalLink;
  // Use Gatsby Link for internal links, and <a> for others
  if (external) {
    return (
      <a
        href={resource.canonicalUrl}
        target="_blank"
        rel="noopener noreferrer"
        ref={ref}
        {...other}
      >
        {children}
      </a>
    );
  }
  return (
    <GatsbyLink
      to={`/${nodeLocale}/${resource.slug}/`}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      ref={ref}
      {...other}
    >
      {children}
    </GatsbyLink>
  );
});

interface ResourceStarProps {
  nodeLocale: NodeLocale;
  isSaved: boolean;
  onToggleBookmark(): any;
  resourceName: string;
}

const ResourceStar = ({
  nodeLocale,
  isSaved,
  onToggleBookmark,
  resourceName,
  ...other
}: ResourceStarProps) => {
  const IconClass = isSaved ? StarFill : Star;

  return (
    <TooltipTrigger tooltipTitle={locale[nodeLocale].save}>
      <button
        className={styles.favoriteWrapper}
        aria-label={`${locale[nodeLocale].save} ${resourceName}`}
        onClick={onToggleBookmark}
      >
        <IconClass className={`${styles.favorite} ${isSaved ? styles.filled : ''}`} />
        <StarFill className={`${styles.favorite} ${styles.favoriteHover}`} />
      </button>
    </TooltipTrigger>
  );
};

const Resource = forwardRef(({
  data,
  compact = true,
  node_locale: nodeLocale,
  from,
  usePersonalization = true,
}: ResourceProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const {
    id,
    provider: { branding },
  } = data;
  const [isSaved, toggleSaved] = useIsSaved(id);
  const { seen, toggleShow } = useContext(SaveBookmarkContext);
  const {
    trackAccessResource,
    trackAddBookmarkResource,
    trackRemoveBookmarkResource,
  } = useMPEvent();

  const getLink = () => {
    const isExternal = data.externalLink;
    let link = '';
    if (isExternal) {
      link = data.canonicalUrl;
    } else if (typeof window !== 'undefined') {
      link = `/${nodeLocale}/${data.slug}`;
    }
    return link;
  };

  const pageLoadEventData = {
    language: nodeLocale.toLowerCase() as 'en-ca' | 'fr-ca',
    url: getLink(),
    url_name: from.includes('wrapper') ? 'resource-wrapper' : 'home',
  } as const;

  const resourceAnalyticData = {
    ...pageLoadEventData,
    id: data.uuid,
    name: data.title,
    slug: data.slug,
    provider: data.provider.name,
    source: from.includes('wrapper') ? 'resource-wrapper' : 'home',
    external: data.externalLink,
  } as const;

  const onAccessResource = () => {
    trackAccessResource(resourceAnalyticData);
    GAEvent.engagement.accessResource(data.slug);
  };

  function toggleBookmark(event: Event) {
    // Don't click into the resource
    event.preventDefault();

    if (!seen) toggleShow(true);
    // fire mixpanel events
    if (isSaved) trackRemoveBookmarkResource(resourceAnalyticData);
    else trackAddBookmarkResource(resourceAnalyticData);
    // @ts-expect-error
    // toggle the bookmark
    toggleSaved();
  }

  const star = usePersonalization ? (
    <ResourceStar
      nodeLocale={nodeLocale}
      isSaved={isSaved}
      onToggleBookmark={toggleBookmark}
      resourceName={data.title}
    />
  ) : null;
  const key = `resource-card-home-${data.slug}`;

  return (
    <Col
      xs={11}
      lg={4}
      className={`mb-sm-2 mb-md-4 ${compact ? 'px-1' : 'pl-0 pr-2 px-lg-3'}`}
      key={key}
    >
      <ResourceLink
        onClick={onAccessResource}
        resource={data}
        nodeLocale={nodeLocale}
        style={{ color: '#fff', textDecoration: 'none' }}
        ref={ref}
      >
        <Card
          className={`${styles.card} ${compact ? styles.compact : ''}`}
          style={{ backgroundColor: branding.background }}
        >
          <GatsbyImage
            image={data.tileImage.gatsbyImageData}
            imgClassName={styles.imageClassName}
            className={styles.image}
            alt=""
          />
          <Card.Body className={styles.body}>
            <div>
              <div className={styles.provider}>
                <GatsbyImage
                  image={data.provider.logo.gatsbyImageData}
                  className={styles.providerLogo}
                  alt={data.provider.logo.title}
                />
                {star}
              </div>
              <Card.Title>
                <span
                  className={styles.resourceTitle}
                  style={{ color: branding.text }}
                >
                  {data.title}{' '}
                  {data.externalLink && <BoxArrowUpRight className="ml-1" />}
                </span>
              </Card.Title>
            </div>
            {data.tags ? (
              <Card.Text>
                {data.tags.slice(0, 3).map((tag) => (
                  <span
                    key={`${key}-resource-tag-${tag}`}
                    className={`${styles.tag} mr-1 mb-1 btn btn-sm`}
                    style={{
                      color: branding.text,
                      backgroundColor: branding.tag,
                    }}
                  >
                    {tag}
                  </span>
                ))}
              </Card.Text>
            ) : null}
          </Card.Body>
        </Card>
      </ResourceLink>
    </Col>
  );
})

export default Resource;
