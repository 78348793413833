// extracted by mini-css-extract-plugin
export const wrapper = "landing-resource-module--wrapper--32se9";
export const selector = "landing-resource-module--selector--R7wId";
export const intro = "landing-resource-module--intro--KM53l";
export const header = "landing-resource-module--header--3XU_l";
export const subTitle = "landing-resource-module--subTitle--3q0e0";
export const question = "landing-resource-module--question--2KQbS";
export const buttonParent = "landing-resource-module--buttonParent--2m1NV";
export const reset = "landing-resource-module--reset--2nu8p";
export const btn = "landing-resource-module--btn--1yuhz";
export const selected = "landing-resource-module--selected--3xO7f";
export const optionIconParent = "landing-resource-module--optionIconParent--3Ccxh";
export const optionIcon = "landing-resource-module--optionIcon--1_VmA";
export const questionTwoParent = "landing-resource-module--questionTwoParent--dtaPu";
export const empty = "landing-resource-module--empty--Pvl_9";